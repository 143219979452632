
import Portifolio from './components/portifolio';

function App() {
  return (
    <div className="App">
      <Portifolio/>
    </div>
  );
}

export default App;
